import React, { useEffect, useRef, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { StakeholderGroup } from "api/stakeholder/stakeholderGroupAPI";
import { Popover, Typography, IconButton, List, ListItem } from "@mui/material";
import currency from "currency.js";
import { MAX_CURRENCY_VALUE } from "./constants/generalConstants";

interface PasswordProps {
  pass: string;
}

export const PasswordInfo = (props: PasswordProps) => {
  const { pass } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const lowercase = new RegExp("(?=.*[a-z])");
  const uppercase = new RegExp("(?=.*[A-Z])");
  const number = new RegExp("(?=.*[0-9])");
  const special = new RegExp("(?=.?[!@#&$;%^&!_-])");

  const check = (pass: boolean) => {
    return pass ? (
      <CheckIcon color="primary" />
    ) : (
      <ClearIcon color="secondary" />
    );
  };

  return (
    <div>
      <IconButton
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography component={"div"} sx={{ padding: 1 }}>
          <List>
            <ListItem>
              {check(pass.length >= 14)}must be at least 14 characters
            </ListItem>
            <ListItem>
              {check(lowercase.test(pass))}must contain a lowercase letter
            </ListItem>
            <ListItem>
              {check(uppercase.test(pass))}must contain an uppercase letter
            </ListItem>
            <ListItem>{check(number.test(pass))}must contain a number</ListItem>
            <ListItem>
              {check(special.test(pass))}must contain a special character
            </ListItem>
          </List>
        </Typography>
      </Popover>
    </div>
  );
};

//This has been modified to strip most HTML tags by removing all between < and >
export const stripRichText = (item: any) => {
  const stringItem = item.toString();
  var regex = /(|<([^>]+)>)/gi;
  let cleanItem = stringItem.replace(regex, "");
  return cleanItem;
};

// for exporting to excel; replace instances of ' | ' with
// a newline character
export const FormatTextForExport = (inText: string) => {
  if (inText) {
    let result = inText.replaceAll(" | ", "\n");
    return result;
  }

  return "";
};

export const RemoveAllHTMLTags = (str: string) => {
  if (str === null || str === "") return "";
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
};

export const markRequired = (label: string, required: boolean | undefined) => {
  if (label == null) {
    return "*Missing Label*";
  }
  return required && label.charAt(label.length - 1) !== "*"
    ? label + "*"
    : label;
};

// Take in a string, trim the whitespace, lowercase all text and remove all diacritics from characters
export const formatStringForCompare = (
  arg: string | number | undefined,
  forSearch?: boolean
): string | null => {
  const replacementMap: { [key: string]: string } = {
    ł: "l",
    ı: "i",
    ɂ: "?",
    "&": "and",
    // add more special character mappings here as required
  };

  // if the item passed in is undefined, return it
  if (!arg && arg !== 0) return null;

  const argStr = typeof arg === "string" ? arg : arg.toString();

  if (forSearch) {
    return argStr.normalize("NFD").replace(/./g, (char) => {
      if (/[\u0300-\u036f]/.test(char)) return "";
      return replacementMap[char] || char;
    });
  } else {
    return argStr
      .trim()
      .toLowerCase()
      .normalize("NFD")
      .replace(/./g, (char) => {
        if (/[\u0300-\u036f]/.test(char)) return "";
        return replacementMap[char] || char;
      });
  }
};

// Take in list of Groups and a new Group, check if the new Group exists in the Group List by formatting the names for comparison. Return true if it exists, false if it does not.
export const checkDuplicateGroup = (
  groupList: StakeholderGroup[],
  newGroupName: string
): string | false => {
  const foundGroup = groupList.find(
    (group) =>
      formatStringForCompare(group.GroupName) ===
      formatStringForCompare(newGroupName)
  );

  return foundGroup ? foundGroup.GroupName : false;
};

export const ShowAsPercent = (percent: any) => {
  if (isNaN(percent)) {
    return "";
  } else {
    let val = `${(percent * 100).toFixed(0)}%`;
    return val;
  }
};

interface Municipal {
  province: string;
  district: string;
}

//takes in string or province - district and returns array
export const convertToAddressArray = (addressString: string): Municipal[] => {
  if (
    !addressString ||
    (typeof addressString === "string" && addressString.trim() === "")
  ) {
    return [{ province: "", district: "" }];
  }

  const addressArray = addressString.split(",").map((item) => {
    const [province, district] = item
      .trim()
      .split("-")
      .map((item) => item.trim());
    return { province, district };
  });

  return addressArray;
};

export const convertToAddressString = (addresses: Municipal[]): string => {
  if (!addresses || addresses.length === 0) {
    return "";
  }

  const addressString = addresses
    .map((address) => `${address.province.trim()} - ${address.district.trim()}`)
    .join(", ");

  return addressString;
};

// Utility function for pluralization
export const pluralize = (word: string, count: number) =>
  `${word}${count !== 1 ? "s" : ""}`;

export function useWhyDidYouRender<T extends { [key: string]: unknown }>(
  props: T
) {
  const prevPropsRef = useRef<T>();
  useEffect(() => {
    const prevProps = prevPropsRef.current;

    if (prevProps) {
      const changedProps = Object.entries(props).reduce((acc, [key, value]) => {
        if (prevProps[key] !== value) {
          acc[key] = [prevProps[key], value];
        }
        return acc;
      }, {} as Record<string, any[]>);

      if (Object.keys(changedProps).length > 0) {
        console.log("Changed props:", changedProps);
      }
    }

    prevPropsRef.current = props;
  });
}

// Function to check the number of items to display in the shadow box
// and return the appropriate color

export const checkShadowBoxCount = (
  count: number,
  optionalCountThreshold?: number
) => {
  const COUNT_THRESHOLD = optionalCountThreshold || 20;
  const HALF_COUNT_THRESHOLD = COUNT_THRESHOLD / 2;

  if (count >= COUNT_THRESHOLD) {
    return "red";
  } else if (count >= HALF_COUNT_THRESHOLD) {
    return "yellow";
  } else {
    return "green";
  }
};

// find an identical obj in arr that has same value as obj terms of keys array
export const findDuplicateObject = <T extends Record<string, any>>(
  arr: T[],
  obj: Partial<T>,
  keysToCompare: string[]
): T | undefined => {
  // check if arr or obj are defined
  if (!arr || !obj) {
    return undefined;
  }
  // Iterate through each arrObj in the arr
  for (const arrObj of arr) {
    let match = true;

    // check if any arrObj matches the target obj for all keys in keysToCompare
    for (const key of keysToCompare) {
      if (!(key in arrObj) || arrObj[key] !== obj[key]) {
        match = false;
        // break the loop as soon as a mismatch is found
        break;
      }
    }
    if (match) {
      return arrObj;
    }
  }
  return undefined;
};

// Function to flatten an array of arrays
export const flatten = (arr: any[]): string[] =>
  arr.reduce((acc, val) => acc.concat(val), []);

/**
 * Formats a list of strings into a natural language list, separating items with commas, and using "and" before the last item. This function accepts any number of string arguments.
 *
 * @param {...string[]} items - An unlimited number of string arguments.
 * @returns {string} A formatted string with items separated by commas and "and" before the last item.
 *     If no items are provided, returns an empty string.
 */
export const formatCommaSeparatedList = (...items: string[]): string => {
  const last = items.pop();
  return items.length ? `${items.join(", ")} and ${last}` : last || "";
};

/**
 * Compares two objects of the same type and returns an object containing
 * only the properties that have different values.
 *
 * @param values - The new object containing the current values.
 * @param initialValues - The original object containing the initial values.
 * @returns An object containing only the properties from `values` that have
 * different values compared to `initialValues`. If no properties have changed,
 * an empty object is returned.
 */
export const getChangedValues = <T extends object>(
  values: T,
  initialValues: T
): Partial<T> => {
  return Object.keys(values).reduce((changedValues, keyValue) => {
    const typedKeyValue = keyValue as keyof T;

    if (values[typedKeyValue] !== initialValues[typedKeyValue]) {
      changedValues[typedKeyValue] = values[typedKeyValue];
    }

    return changedValues;
  }, {} as Partial<T>);
};

/**
 * Validates a given amount as a currency value. The function handles both string and number inputs.
 * It trims the input, removes non-numeric characters (except for decimal point and minus sign), and checks if the cleaned amount is a valid number.
 * It also ensures that the value does not exceed the maximum allowable value.
 *
 * @param {string | number | undefined} amount - The amount to be validated. Can be a string, number, or undefined.
 * @returns {boolean} - Returns true if the amount is a valid currency value, false otherwise.
 */
export const validateCurrency = (
  amount: string | number | undefined
): boolean => {
  if (
    amount === undefined ||
    amount === null ||
    amount.toString().trim() === ""
  ) {
    return false;
  }

  // Trim the input value
  const trimmedAmount = amount.toString().trim();

  // Regular expression to reject strings containing anything other than digits 0-9, $, (), , and .
  const invalidCharRegex = /[^0-9$(),.\s-]/;

  // Check if the trimmed amount contains any invalid characters
  if (invalidCharRegex.test(trimmedAmount)) {
    return false;
  }

  // Use currency.js to parse the amount
  try {
    const parsedAmount = currency(trimmedAmount, {
      errorOnInvalid: true,
    });

    // Check if the parsed amount is valid
    if (
      parsedAmount.format() !== "NaN" &&
      !isNaN(parsedAmount.value) &&
      parsedAmount.value <= MAX_CURRENCY_VALUE &&
      parsedAmount.value >= -MAX_CURRENCY_VALUE
    ) {
      return true;
    }
  } catch (error) {
    return false;
  }

  return false;
};

/**
 * Compare original and updated data based on a single key and categorize changes into new, deleted, and updated items.
 * @param originalData Array of original data objects.
 * @param updatedData Array of updated data objects.
 * @param compareKey Unique Key string to compare for detecting changes.
 * @returns Object containing newItems, deletedItems, and updatedItems arrays.
 */
export const getChangedData = <T extends Record<string, any>>(
  originalData: T[],
  updatedData: T[],
  compareKey: keyof T
) => {
  const originalMap = new Map(
    originalData?.map((item) => [item[compareKey], item])
  );
  const updatedMap = new Map(
    updatedData?.map((item) => [item[compareKey], item])
  );
  // Find new items (items in updatedData that are not in originalData)
  let newItems = updatedData?.filter(
    (item) => !originalMap?.has(item[compareKey])
  );

  // Find deleted items (items in originalData that are not in updatedData)
  let deletedItems = originalData?.filter(
    (item) => !updatedMap?.has(item[compareKey])
  );

  // Find updated items including index changes
  const updatedItems = updatedData?.filter(
    (updatedItem: any, updatedIndex: number) => {
      const originalItem = originalMap?.get(updatedItem[compareKey]);
      const originalIndex = originalData?.findIndex(
        (item) => item[compareKey] === updatedItem[compareKey]
      );
      return originalItem && originalIndex !== updatedIndex;
    }
  );

  return {
    newItems,
    deletedItems,
    updatedItems,
  };
};

// This is used to transform  ProximityIndicatorLSD to be 'PI-LSD' join by ',' for IppDisplayChip
interface PILSDItem {
  ProximityIndicator: string;
  LegalSubdivision: string;
}
export const transformPILSDs = (items: PILSDItem[]) => {
  return items
    ?.flatMap((item: PILSDItem) => {
      if (!item.LegalSubdivision) {
        // If no LegalSubdivision, return ProximityIndicator alone or an empty string if neither exists
        return item.ProximityIndicator ? [item.ProximityIndicator] : [];
      }

      const itemsList = item?.LegalSubdivision.split(",").map((itm: string) =>
        itm.trim()
      );

      if (!item.ProximityIndicator) {
        return itemsList; // Return items directly if no category
      }

      return `${item.ProximityIndicator}-${itemsList.join("; ")}`;
    })
    .join(", ");
};

export const mergeProximityIndicatorLSDs = (
  oldLSDs: PILSDItem[] = [],
  dupeLSDs: PILSDItem[] = []
) => {
  // Create a Map to store ProximityIndicator as key and a Set of LegalSubdivision as value
  const lsdMap = new Map();
  // Array to hold entries with empty ProximityIndicator separately
  const emptyProximityIndicatorLSDs: PILSDItem[] = [];
  // Helper function to merge arrays
  const mergeLSDs = (lsds: PILSDItem[]) => {
    lsds?.forEach((item: PILSDItem) => {
      if (!item.ProximityIndicator) {
        emptyProximityIndicatorLSDs.push(item);
      } else {
        const indicator = item.ProximityIndicator;
        if (!lsdMap.has(indicator)) {
          lsdMap.set(indicator, new Set());
        }
        if (item.LegalSubdivision) {
          item.LegalSubdivision.split(",")?.forEach((lsd) =>
            lsdMap.get(indicator).add(lsd.trim())
          );
        }
      }
    });
  };

  // Ensure oldLSDs and dupeLSDs are arrays
  if (Array.isArray(oldLSDs)) {
    mergeLSDs(oldLSDs);
  }
  if (Array.isArray(dupeLSDs)) {
    mergeLSDs(dupeLSDs);
  }

  // Create the merged ProximityIndicatorLSDs array
  const mergedLSDs = Array.from(lsdMap.entries()).map(
    ([ProximityIndicator, legalSubdivisionSet]) => ({
      ProximityIndicator,
      LegalSubdivision: Array.from(legalSubdivisionSet).join(", "),
    })
  );

  // Combine merged LSDs with those having empty ProximityIndicator
  return mergedLSDs.concat(emptyProximityIndicatorLSDs);
};

// Utility function to compare arrays
export const arraysAreEqual = <T,>(a: T[], b: T[]): boolean =>
  a.length === b.length && a.every((value, index) => value === b[index]);

export const truncateText = (text: string, maxLength: number) => {
  if (text?.length <= maxLength) {
    return text;
  }
  return text?.substring(0, maxLength) + "...";
};

export const ensureStringArray = (val: string | string[] | null): string[] => {
  if (!val) return [];
  const arr = Array.isArray(val) ? val : val.split(",");
  return arr.map((s) => s.trim()).filter(Boolean);
};
