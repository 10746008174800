import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addContact, updContact } from "./ContactSlice";
import { Grid, Typography } from "@mui/material";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import LoadingIndicator from "components/LoadingIndicator";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import { ChangeEvent, useEffect, useState } from "react";
import { fetchStakeholderGroups } from "../groups/StakeholderGroupSlice";
import { IppFormDivider } from "components/IppFormDivider";
import { IppRating } from "components/IppRating";
import { ValidationSchema } from "./ContactValidation";
import { IppMultiTextField } from "components/IppMultiTextField";
import { fetchContactGroupsByContact } from "features/stakeholder/contactGroup/ContactGroupSlice";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { User } from "api/usersAPI";
import { ClientModule } from "api/clientModuleAPI";
import { UserRole } from "api/userRoleAPI";
import { fetchUsers } from "features/users/UsersSlice";
import { fetchChildTagsByRecordType } from "../admin/tag/TagSlice";
import { PersonRenderOption } from "utils/renderFunctions";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppDynamicPILSD } from "components/IppDynamicPILSD";
import { fetchProximityIndicatorLSDs } from "features/datalists/PILSDSlice";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import { fetchClientInteractionTypes } from "../admin/clientInteractionTypes/ClientInteractionTypeSlice";
import { selectAllUserRoles } from "features/roles/UserRoleSlice";
import { IppCheckbox } from "components/IppCheckbox";

interface ContactChildFormProps {
  setAddingItem: any;
  fullName: string;
  onClose: any;
  contact?: any;
  isTokenCreate?: boolean;
  parentTitle?: string;
}

export const ContactChildForm = (props: ContactChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const snackbarConstants = useSnackBarConstants();

  const isTokenCreate = props.isTokenCreate || false;
  const parentTitle = props.parentTitle || "";

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_eng_lvlOfEngagement,
    ft_all_Tag,
    ft_eng_PILSD,
    ft_eng_PreferredCommunicationMethod,
  } = useSelector((state: RootState) => state.client);

  const { setAddingItem, fullName, onClose, contact } = props;

  let splitName = fullName.trim().split(" ");

  const [listGroups, setListGroups] = useState<Array<string>>([]);
  const [listIssues, setListIssues] = useState<Array<string>>([]);
  const [didUpdateContact, setDidUpdateContact] = useState(false);
  const [tagItems, setTagItems] = useState(
    contact && contact.Tags ? contact.Tags : []
  );

  const { stakeholderGroupList, stakeholderGroupsById } = useSelector(
    (state: RootState) => state.stakeholderGroups
  );

  const stakeholderGroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );
  const { proximityIndicatorLSDList, proximityIndicatorLSDsById } = useSelector(
    (state: RootState) => state.proximityIndicatorLSDs
  );

  const proximityIndicatorLSDs = ft_eng_PILSD
    ? proximityIndicatorLSDList.map((id) => proximityIndicatorLSDsById[id])
    : [];

  const {
    contactGroupList,
    contactGroupsById,
    isLoading: contactGroupIsLoading,
  } = useSelector((state: RootState) => state.contactGroups);

  const contactGroups = contactGroupList.map((id) => contactGroupsById[id]);

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientmodules = clientModuleList.map((id) => clientModulesById[id]);

  const {
    userList,
    usersById,
    isLoading: userIsLoading,
  } = useSelector((state: RootState) => state.users);

  const userRoles = useSelector((state: RootState) =>
    selectAllUserRoles(state)
  );

  //Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item: User) => {
    if (!item.IsActive || item.CompanyID != currentProfile.CompanyID)
      return false;
    if (item.IsClientAdmin) {
      return true;
    } else {
      let found = userRoles.filter((role) => {
        return item.UserAccountID === role.UserAccountID;
      });
      if (found && found.length > 0) {
        let check = clientmodules.find((object: ClientModule) => {
          let clientMod = found.find((mod: UserRole) => {
            return (
              object.ClientModuleID === mod.ClientModuleID &&
              (object.ModuleID === 3 || object.ModuleID === 2)
            );
          });
          return clientMod;
        });
        return check;
      } else return false;
    }
  });

  const {
    childTagList,
    childTagsById,
    isChildLoading: tagIsLoading,
  } = useSelector((state: RootState) => state.tag);

  const tags = ft_all_Tag ? childTagList.map((id) => childTagsById[id]) : [];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (stakeholderGroups.length === 0) {
          dispatch(fetchStakeholderGroups(accessToken));
        }
        if (allUsers.length === 0) {
          dispatch(fetchUsers(accessToken, clientId));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    clientId,
    dispatch,
    getAccessTokenSilently,
    stakeholderGroups,
    contactGroups,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (ft_all_Tag) {
          dispatch(fetchChildTagsByRecordType(accessToken, "Contact"));
        }
        if (ft_eng_PILSD) {
          dispatch(fetchProximityIndicatorLSDs(accessToken));
        }
        if (ft_eng_PreferredCommunicationMethod) {
          // Prevent reloading of main form
          !clientInteractionTypes &&
            dispatch(fetchClientInteractionTypes(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  // convert tags to objects for multi autocomplete field
  useEffect(() => {
    // effect
    if (
      ft_all_Tag &&
      !tagIsLoading &&
      tags &&
      tags.length > 0 &&
      contact &&
      contact.Tags
    ) {
      let newList = tags.filter((item) => contact.Tags.includes(item.Name));
      setTagItems(newList);
    }
    return () => {
      // cleanup
    };
  }, [contact, tagIsLoading]);

  useEffect(() => {
    // effect
    if (contact && !contactGroupIsLoading) {
      let newList: string[] = [];

      stakeholderGroups
        .filter((group) =>
          contactGroups.some(
            (contactGroup) =>
              contactGroup.GroupID === group.GroupID &&
              contactGroup.ContactID === contact.ContactID
          )
        )
        .map((item) => newList.push(item.GroupName));

      setListGroups(newList);
    }
    return () => {
      // cleanup
    };
  }, [contact, contactGroupIsLoading]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (contact && parentTitle !== t("objPlt:objects.group.name")) {
          dispatch(fetchContactGroupsByContact(accessToken, contact.ContactID));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [contact, dispatch, getAccessTokenSilently]);

  let formData;

  if (contact) {
    formData = contact;
  } else {
    formData = {
      Address: "",
      AltPhone: "",
      FirstName: splitName[0] || "",
      Surname: splitName[1] || "",
      ContactTitle: "",
      Country: "",
      Email: "",
      PostalCode: "",
      PrimaryPhone: "",
      ProvinceState: "",
      TownCity: "",
      ClientID: clientId,
      LevelOfInfluence: 0,
      LevelOfInterest: 0,
      RelationshipOwner: null,
      Tags: [],
      ProximityIndicatorLSDs: [],
      ContactMethodID: -1,
    };
  }

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (ft_eng_PILSD) {
          values = {
            ...values,
            ProximityIndicatorLSDs: Array.isArray(values.ProximityIndicatorLSDs)
              ? values.ProximityIndicatorLSDs.filter(
                  (pilsd: any) =>
                    pilsd.ProximityIndicator !== "" ||
                    pilsd.LegalSubdivision !== ""
                )
              : [],
          };
        }
        if (contact) {
          // reset null to listGroup
          dispatch(
            updContact(
              accessToken,
              values.ContactID,
              listGroups,
              null,
              values,
              false,
              snackbarConstants
            )
          );
          setDidUpdateContact(true);
        } else {
          dispatch(
            addContact(
              accessToken,
              values,
              listGroups,
              listIssues,
              false,
              snackbarConstants,
              isTokenCreate
            )
          );
        }
        setAddingItem(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const {
    // Preferred method of contact feature
    clientInteractionTypesById,
    clientInteractionTypeList,
    isLoading: typeIsLoading,
  } = useSelector((state: RootState) => state.clientInteractionTypes);

  const clientInteractionTypes = clientInteractionTypeList.map(
    (id) => clientInteractionTypesById[id]
  );

  const formik = useFormik({
    initialValues: formData,
    validationSchema: ValidationSchema(),
    onSubmit: onSub,
  });

  let detailForm =
    clientIsLoading || userIsLoading || tagIsLoading ? (
      <LoadingIndicator />
    ) : (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {contact
                ? t("strGen:buttons.editobj", {
                    objectname: t("objPlt:objects.contact.name"),
                  })
                : t("strGen:buttons.addnewobj", {
                    objectname: t("objPlt:objects.contact.name"),
                  })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="FirstName"
              label={t("objPlt:objects.contact.fields.firstname")}
              required={false}
              value={formik.values.FirstName}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.FirstName}
              errorsExpression={formik.errors.FirstName}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="Surname"
              label={t("objPlt:objects.contact.fields.surname")}
              required={true}
              value={formik.values.Surname}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Surname}
              errorsExpression={formik.errors.Surname}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="ContactTitle"
              label={t("objPlt:objects.contact.fields.title")}
              required={false}
              value={formik.values.ContactTitle}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.ContactTitle}
              errorsExpression={formik.errors.ContactTitle}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="RelationshipOwner"
              options={users}
              renderOption={(props: any, option: User) =>
                PersonRenderOption(props, option, [option.EmailAddress], "")
              }
              value={allUsers.find((obj) => {
                return obj.UserAccountID === formik.values.RelationshipOwner;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "RelationshipOwner",
                    newValue.UserAccountID
                  );
                } else {
                  formik.setFieldValue("RelationshipOwner", null);
                }
              }}
              label={t("objPlt:objects.contact.fields.relationshipowner")}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) =>
                option.FirstName + " " + option.Surname
              }
              errorFunction={formik.errors.RelationshipOwner}
              touchedFunction={formik.touched.RelationshipOwner}
              textValueFunction={formik.values.RelationshipOwner}
              autoPopulate={false}
            />
          </Grid>
          <Grid item xs={6}>
            <IppCheckbox
              id="InternalUser"
              label={t("objPlt:objects.contact.fields.internaluser")}
              value={formik.values.InternalUser}
              isEditing={true}
              onChangeFunction={formik.handleChange}
            />
          </Grid>
          <IppLocationAutoComplete
            formik={formik}
            isEditing={true}
            setIsEditing={null}
            parentTitle={"Contact"}
          />
          {ft_all_Tag && tags.length > 0 && (
            <Grid item xs={6}>
              <IppTokenMultiSelect
                id="Tags"
                label={t("objPlt:objects.contact.fields.tag", {
                  count: formik.values.Tags ? formik.values.Tags.length : 1,
                })}
                required={false}
                options={tags}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={tagItems}
                setValue={(newValue: any) => {
                  setTagItems(newValue);
                  formik.setFieldValue("Tags", newValue);
                }}
                touchedFunction={formik.touched.Tags}
                errorFunction={formik.errors.Tags}
                isEditing={true}
                optionLabelFunction={(option: any) => option.Name}
                setIsEditing={null}
                toolTip={t("strGen:tooltips.tags")}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <IppTextField
              id="PrimaryPhone"
              label={t("objPlt:objects.contact.fields.phoneprimary")}
              required={false}
              value={formik.values.PrimaryPhone}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.PrimaryPhone}
              errorsExpression={formik.errors.PrimaryPhone}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="AltPhone"
              label={t("objPlt:objects.contact.fields.phonealternate")}
              required={false}
              value={formik.values.AltPhone}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.AltPhone}
              errorsExpression={formik.errors.AltPhone}
              isEditing={true}
              setIsEditing={null}
            />
          </Grid>
          <Grid item xs={6}>
            <IppMultiTextField
              id="Email"
              label={t("objPlt:objects.contact.fields.emailaddress")}
              required={false}
              options={[]}
              noOptionsText={t("strGen:components.nooptionstext")}
              value={formik.values.Email}
              setValue={(newValue: any) =>
                formik.setFieldValue("Email", newValue.toString())
              }
              touchedFunction={formik.touched.Email}
              errorFunction={formik.errors.Email}
              isEditing={true}
              setIsEditing={null}
              multiple={true}
              autoPopulate={false}
              freeSolo={true}
              emailSpaceToken={true}
            />
          </Grid>

          {ft_eng_PreferredCommunicationMethod && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="ContactMethodID"
                options={clientInteractionTypes}
                value={clientInteractionTypes.find((obj) => {
                  return (
                    obj.InteractionTypeID === formik.values.ContactMethodID
                  );
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "ContactMethodID",
                      newValue.InteractionTypeID
                    );
                  } else {
                    formik.setFieldValue("ContactMethodID", -1);
                  }
                }}
                label={t(
                  "objPlt:objects.contact.fields.preferredcommunicationmethod"
                )}
                isEditing={true}
                setIsEditing={null}
                optionLabelFunction={(option: any) =>
                  option.InteractionTypeName
                }
                errorFunction={formik.errors.InteractionTypeName}
                touchedFunction={formik.touched.InteractionTypeName}
                textValueFunction={formik.values.InteractionTypeName}
              />
            </Grid>
          )}

          {ft_eng_lvlOfEngagement && (
            <>
              <Grid item xs={6}>
                <IppRating
                  id="LevelOfInfluence"
                  label={t("objPlt:objects.contact.fields.levelofinfluence")}
                  value={formik.values.LevelOfInfluence}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={6}>
                <IppRating
                  id="LevelOfInterest"
                  label={t("objPlt:objects.contact.fields.levelofinterest")}
                  value={formik.values.LevelOfInterest}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
            </>
          )}
          {ft_eng_PILSD && (
            <>
              <Grid xs={12} item>
                <IppFormDivider
                  title={t("objPlt:platformwide.headers.proximitylsd")}
                />
                <IppDynamicPILSD
                  id="ProximityIndicatorLSDs"
                  options={proximityIndicatorLSDs}
                  value={formik.values.ProximityIndicatorLSDs}
                  onChangeFunction={(item: any) => {
                    formik.setFieldValue("ProximityIndicatorLSDs", item);
                  }}
                  touchedFunction={formik.touched.ProximityIndicatorLSDs}
                  label=""
                  isEditing={true}
                  setIsEditing={() => {}}
                />
              </Grid>
            </>
          )}
          <>
            <IppFormDivider
              title={t("objPlt:objects.contact.headers.associatedgroups", {
                count: listGroups.length,
              })}
            />
            <Grid item xs={12}>
              <IppTokenAutocomplete
                id="GroupList"
                label={t("objPlt:objects.contact.fields.groupsinvolved", {
                  count: listGroups.length,
                })}
                options={stakeholderGroups.map((option) => option.GroupName)}
                selectedValues={listGroups}
                setSelectedValues={setListGroups}
                isEditing={true}
                setIsEditing={null}
              />
            </Grid>
          </>
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={true}
              setIsEditing={null}
              isAdding={!contact}
              showCancel={true}
              resetFunction={onClose}
            />
          </Grid>
        </Grid>
      </form>
    );

  return <div id="contact-child-form">{detailForm}</div>;
};
