import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchSinglePayment } from "./PaymentSlice";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";

import { PaymentForm } from "./PaymentForm";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";
import { Trans } from "react-i18next";

interface PaymentDetProps {
  paymentID: string;
}

export const PaymentDetailPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const {
    isLoading,
    error: compError,
    paymentsById,
  } = useSelector((state: RootState) => state.payments);

  // get payment ID from url param and attempt to find company in store
  const { paymentID } = useParams<PaymentDetProps>();
  const payID = parseInt(paymentID, 10);
  const payment = paymentsById[payID];
  const prevPaymentRef = useRef(payment);
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (!prevPaymentRef.current && !payment) {
          dispatch(fetchSinglePayment(accessToken, payID));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, dispatch, payment]);

  let paymentDetView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      {compError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <span>
            <b>{t("strGen:errors.wentwrong")}</b> - {compError.toString()}
          </span>
        </Alert>
      )}
      {!payID && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <div>
            <Trans
              i18nKey="strGen:errors.invalidid"
              /* eslint-disable-next-line jsx-a11y/heading-has-content */
              components={[<h1 />, <div />]}
              values={{
                variable: paymentID,
                fieldname: t("objBen:objects.payment.name"),
              }}
            />
          </div>
        </Alert>
      )}
      <div>{payment && <PaymentForm payment={payment} />}</div>
    </>
  );

  return <div id="payment-detail-page">{paymentDetView}</div>;
};
